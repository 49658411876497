<template>
    <div class="todo-app">
      <h1>待办事项列表</h1>
      <div>
        <input v-model="newTodo" @keyup.enter="addTodo" placeholder="请输入新的待办事项" />
        <button @click="addTodo">添加</button>
      </div>
      <ul>
        <li v-for="(todo, index) in todos" :key="index">
          {{ todo.text }}
          <button @click="removeTodo(index)">删除</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TodoApp',
    data() {
      return {
        newTodo: '',
        todos: []
      };
    },
    methods: {
      addTodo() {
        if (this.newTodo.trim()) {
          this.todos.push({ text: this.newTodo });
          this.newTodo = '';
        }
      },
      removeTodo(index) {
        this.todos.splice(index, 1);
      }
    }
  };
  </script>
  
  <style scoped>
  .todo-app {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  h1 {
    text-align: center;
  }
  div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  button {
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
  }
  button:hover {
    background-color: #0056b3;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  </style>